import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Warren({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("field").color
  const container_radius = styles.forElement("container_border").borderRadius

  const linkDefaults = {
    settings: {
      radius: "4px",
      shadow: "0px 1px 8px rgba(0,0,0, 0.05)",
      columns: 1,
      backgroundColor: "#54675B",
      textColor: "#F1F0EA",
      iconBorder: "0",
      iconBorderColor: "#E9EEF4",
      fontSize: "20",
      fontWeight: "normal",
      iconColorStyle: "platform",
      iconColor: "#58ab7e",
      linkBorder: "1px",
      linkBorderColor: "#F1F0EA"
    },
    children: [
      {
        type: "Link",
        url: "https://convertkit.com",
        text: "United We Eat cookbook",
        icon: "envelope-open",
        hasIcon: "none"
      },
      {
        type: "Link",
        url: "https://twitter.com",
        text: "Reserve your spot at the next popup",
        icon: "twitter",
        hasIcon: "none"
      },
      {
        type: "Link",
        url: "https://instagram.com",
        text: "Download a free recipe",
        icon: "instagram",
        hasIcon: "none"
      },
      {
        type: "Link",
        url: "https://instagram.com",
        text: "See the website",
        icon: "instagram",
        hasIcon: "none"
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div
        className="formkit-banner"
        style={{
          backgroundColor: styles.forElement("banner").backgroundColor
        }}
      />
      <div className="formkit-banner" style={styles.forElement("banner")} />
      <header>
        <Elements.Heading
          className="formkit-heading"
          name="heading"
          defaults={{
            content: "Jordan Russo"
          }}
        />
      </header>
      <main>
        <div
          className="formkit-wrapper"
          style={{
            "--radius": container_radius + "px"
          }}
        >
          <div
            className="formkit-content-container"
            style={styles.forElement("content_background")}
          >
            <Elements.Heading
              className="formkit-content-heading"
              name="content_heading"
              tag="h2"
              defaults={{
                content: "NICE TO MEET YOU"
              }}
            />
            <Elements.Region
              className="formkit-collection-container"
              name="content_region"
            >
              <Elements.Content
                defaults={{
                  content:
                    "<p>Hi, my name is Jordan Russo, and I'm a professional chef and restaurateur. Check out my book, popup, recipes, and more below.</p>"
                }}
              />
              <Elements.Links
                defaults={linkDefaults.settings}
                defaultChildren={linkDefaults.children}
              />
            </Elements.Region>
          </div>
          <div
            className="formkit-form-container"
            style={styles.forElement("form_background")}
          >
            <Elements.Heading
              className="formkit-form-heading"
              name="form_heading"
              tag="h2"
              defaults={{
                content: "Subscribe for updates"
              }}
            />
            <Elements.Region
              className="formkit-form-content"
              name="form_content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Be the first to know about new recipes and restaurant popups."
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxes_color
                }}
              >
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up now" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
          <Elements.BuiltWith background="background" image="background" />
        </div>
      </main>
    </Elements.LandingPage>
  )
}

export default createTemplate(Warren, { name: "Warren" })
